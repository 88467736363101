
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { FalseTickIcon, TrueTickIcon } from '../../../assets/icons/compareFeatures';
import { DropArrowIcon, PlusPlanIcon, StandartPlanIcon } from '../../../assets/icons/price';
import './CompareFeatures.css';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { AdminPanelIcon, AnaliticsIcon, ClientsIcon, CommunicationsIcon, DesignIcon, MarketingAndAdsIcon, OnlineStoreIcon, OrdersIcon, PaymentsIcon, ProductPricesIcon, SalesIcon, SecurityIcon, ServiceAndSupportIcon, ShippingIcon } from "../../../assets/icons/compareFeatures";
import { getRandomId } from "../../../assets/utils/utils";

function CompareFeatures({ onSignupOpen }) {
    const { prices } = useContext(TranslationContext);
    const { features } = prices;
    const {
        sales,
        onlineStore,
        admin,
        productPrice,
        shipping,
        payments,
        design,
        orders,
        clients,
        marketing,
        analitics,
        security,
        communication,
        serviceSupport,
    } = features;

    const SALES_CHANNELS = {
        title: `${sales.title}`,
        icon: SalesIcon,
        _id: 1,
        items: [
            {
                title: `${sales.items.title1}`,
                standart_value: {
                    type: 'text',
                    value: 'Sellavi',
                },
                plus_value: {
                    type: 'text',
                    value: 'Sellavi Plus',
                },
                _id: getRandomId(),
            },
            {
                title: `${sales.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${sales.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${sales.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${sales.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${sales.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${sales.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${sales.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const ONLINE_STORES = {
        title: `${onlineStore.title}`,
        icon: OnlineStoreIcon,
        _id: 2,
        items: [
            {
                title: `${onlineStore.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title9}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title10}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title11}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title12}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title13}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title14}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title15}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title16}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title17}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${onlineStore.items.title18}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const ADMIN_PANEL = {
        title: `${admin.title}`,
        icon: AdminPanelIcon,
        _id: 3,
        items: [
            {
                title: `${admin.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${admin.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${admin.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${admin.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${admin.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${admin.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${admin.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${admin.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const PRODUCT_PRICES_AND_INVENTORY = {
        title: `${productPrice.title}`,
        icon: ProductPricesIcon,
        _id: 4,
        items: [
            {
                title: `${productPrice.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title9}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title10}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title11}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title12}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title13}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title14}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title15}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title16}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title17}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title18}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title19}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${productPrice.items.title20}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const SHIPPING_AND_DELIVERIES = {
        title: `${shipping.title}`,
        icon: ShippingIcon,
        _id: 5,
        items: [
            {
                title: `${shipping.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title9}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title10}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title11}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${shipping.items.title12}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },

        ],
    }
    const PAYMENTS = {
        title: `${payments.title}`,
        icon: PaymentsIcon,
        _id: 6,
        items: [
            {
                title: `${payments.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            // {
            //     title: `${payments.items.title2}`,
            //     standart_value: {
            //         type: 'icon',
            //         value: true,
            //     },
            //     plus_value: {
            //         type: 'icon',
            //         value: true,
            //     },
            //     _id: getRandomId(),
            // },
            {
                title: `${payments.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${payments.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: false,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${payments.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${payments.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${payments.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${payments.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${payments.items.title9}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const DESIGN = {
        title: `${design.title}`,
        icon: DesignIcon,
        _id: 7,
        items: [
            {
                title: `${design.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${design.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${design.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${design.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            // {
            //     title: 'Online invoicing',
            //     standart_value: {
            //         type: 'icon',
            //         value: true,
            //     },
            //     plus_value: {
            //         type: 'icon',
            //         value: true,
            //     },
            //     _id: getRandomId(),
            // },
            {
                title: `${design.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            // {
            //     title: 'Phone payment',
            //     standart_value: {
            //         type: 'icon',
            //         value: true,
            //     },
            //     plus_value: {
            //         type: 'icon',
            //         value: true,
            //     },
            //     _id: getRandomId(),
            // },
            // {
            //     title: 'Recurring subscriptions',
            //     standart_value: {
            //         type: 'icon',
            //         value: true,
            //     },
            //     plus_value: {
            //         type: 'icon',
            //         value: true,
            //     },
            //     _id: getRandomId(),
            // },
            {
                title: `${design.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${design.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${design.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: false,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const ORDERS = {
        title: `${orders.title}`,
        icon: OrdersIcon,
        _id: 8,
        items: [
            {
                title: `${orders.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title9}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title10}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${orders.items.title11}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const CLIENTS = {
        title: `${clients.title}`,
        icon: ClientsIcon,
        _id: 9,
        items: [
            {
                title: `${clients.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title9}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${clients.items.title10}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const MARKETING_AND_ADS = {
        title: `${marketing.title}`,
        icon: MarketingAndAdsIcon,
        _id: 10,
        items: [
            {
                title: `${marketing.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title9}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title10}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title11}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title12}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title13}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title14}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${marketing.items.title15}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },

        ],
    }
    const ANALITICS = {
        title: `${analitics.title}`,
        icon: AnaliticsIcon,
        _id: 11,
        items: [
            {
                title: `${analitics.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${analitics.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${analitics.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${analitics.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${analitics.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${analitics.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const SECURITY = {
        title: `${security.title}`,
        icon: SecurityIcon,
        _id: 12,
        items: [
            {
                title: `${security.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${security.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${security.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${security.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${security.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${security.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${security.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }
    const COMMUNICATIONS = {
        title: `${communication.title}`,
        icon: CommunicationsIcon,
        _id: 13,
        items: [
            {
                title: `${communication.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${communication.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${communication.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${communication.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${communication.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${communication.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${communication.items.title7}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${communication.items.title8}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${communication.items.title9}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }

    const SERVICE_AND_SUPPORT = {
        title: `${serviceSupport.title}`,
        icon: ServiceAndSupportIcon,
        _id: 14,
        items: [
            {
                title: `${serviceSupport.items.title1}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${serviceSupport.items.title2}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${serviceSupport.items.title3}`,
                standart_value: {
                    type: 'icon',
                    value: true,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${serviceSupport.items.title4}`,
                standart_value: {
                    type: 'icon',
                    value: false,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${serviceSupport.items.title5}`,
                standart_value: {
                    type: 'icon',
                    value: false,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
            {
                title: `${serviceSupport.items.title6}`,
                standart_value: {
                    type: 'icon',
                    value: false,
                },
                plus_value: {
                    type: 'icon',
                    value: true,
                },
                _id: getRandomId(),
            },
        ],
    }

    const PLAN_FEATURES = [
        SALES_CHANNELS,
        ONLINE_STORES,
        ADMIN_PANEL,
        PRODUCT_PRICES_AND_INVENTORY,
        SHIPPING_AND_DELIVERIES,
        PAYMENTS,
        DESIGN,
        ORDERS,
        CLIENTS,
        MARKETING_AND_ADS,
        ANALITICS,
        SECURITY,
        COMMUNICATIONS,
        SERVICE_AND_SUPPORT,
    ]

    const [selectedItems, setSelectedItems] = useState([PLAN_FEATURES[0]._id]);
    const { width } = useWindowSize()
    const toggleItem = (id) => {

        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(item => item !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const containerRef = useRef(null);
    const headerRef = useRef(null);
    const fixedHeaderRef = useRef(null);
    const handleScroll = () => {
        const headerRect = headerRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        // const fixedHeaderRect = fixedHeaderRef.current.getBoundingClientRect();
        // Show fixed header when original header is out of view
        if (headerRect.top < (width > 880 ? 34 : 17)) {
            fixedHeaderRef.current.style.display = 'flex';
        } else {
            fixedHeaderRef.current.style.display = 'none';
        }
        // Hide fixed header when scrolling past the compare-features block
        if (containerRect.bottom < 200) {
            fixedHeaderRef.current.style.display = 'none';
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log(selectedItems)

    const variants = (totalItems) => ({
        open: {
            height: 'auto',
            transition: { staggerChildren: 0.07, delayChildren: 0.2, duration: 0.03 }
        },
        closed: {
            height: 0,

            transition: { staggerChildren: 0.05, staggerDirection: -1, duration: totalItems * 0.03, when: "afterChildren" }
        }
    })

    const variantsLi = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 }
            }
        },
        closed: {
            y: 50,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 }
            }
        }
    };

    const useDimensions = ref => {
        const dimensions = useRef({ width: 0, height: 0 });

        useEffect(() => {
            dimensions.current.width = ref.current.offsetWidth;
            dimensions.current.height = ref.current.offsetHeight;
        }, []);

        return dimensions.current;
    };


    const containerRefItem = useRef(null);
    const { height } = useDimensions(containerRefItem);

    return (
        <div className='compare-features' ref={containerRef}>
            <h4 className='compare-features__title'>{features.title}</h4>
            <div className='compare-features__table'>
                <div className='compare-features__table-heading' ref={headerRef}>
                    <div></div>
                    <div className='compare-features__table-heading-content'>
                        <StandartPlanIcon
                            mainClassName={'compare-features__table-heading-icon'}
                        />
                        <button className='compare-features__table-heading-btn' type='button' onClick={onSignupOpen}>
                            <p className='compare-features__table-heading-btn-title'>{features.textBtnCreateAcc}</p>
                            <span className='compare-features__table-heading-btn-subtitle'>{features.textBtnTrial}</span>
                        </button>
                    </div>
                    <div className='compare-features__table-heading-content'>
                        <PlusPlanIcon
                            mainClassName={'compare-features__table-heading-icon compare-features__table-heading-icon_plus'}
                        />
                        <button className='compare-features__table-heading-btn' type='button' onClick={onSignupOpen}>
                            <p className='compare-features__table-heading-btn-title'>{features.textBtnCreateAcc}</p>
                            <span className='compare-features__table-heading-btn-subtitle'>{features.textBtnTrial}</span>
                        </button>
                    </div>
                </div>
                <div className='compare-features__table-heading-fixed' ref={fixedHeaderRef}>
                    <div className='compare-features__table-heading-fixed-content'>
                        <div className='compare-features__table-heading'>
                            <div className='compare-features__table-heading-title-content'>
                                <p className='compare-features__table-heading-title'>{features.title}</p>
                            </div>
                            <div className='compare-features__table-heading-content'>
                                <StandartPlanIcon
                                    mainClassName={'compare-features__table-heading-icon'}
                                />
                                <button className='compare-features__table-heading-btn' type='button' onClick={onSignupOpen}>
                                    <p className='compare-features__table-heading-btn-title'>{features.textBtnCreateAcc}</p>
                                    <span className='compare-features__table-heading-btn-subtitle'>{features.textBtnTrial}</span>
                                </button>
                            </div>
                            <div className='compare-features__table-heading-content'>
                                <PlusPlanIcon
                                    mainClassName={'compare-features__table-heading-icon compare-features__table-heading-icon_plus'}
                                />
                                <button className='compare-features__table-heading-btn' type='button' onClick={onSignupOpen}>
                                    <p className='compare-features__table-heading-btn-title'>{features.textBtnCreateAcc}</p>
                                    <span className='compare-features__table-heading-btn-subtitle'>{features.textBtnTrial}</span>
                                </button>
                            </div>
                        </div>
                    </div>


                </div>

                {PLAN_FEATURES.map((item, i) => (
                    <div className={`compare-features__table-item ${i % 2 !== 0 ? 'compare-features__table-item_even' : ''} ${selectedItems.includes(item._id) ? 'compare-features__table-item_selected' : ''}`} key={`compare-features__table-item_${item._id}`}>
                        <button className='compare-features__table-item-head' type='button' onClick={() => toggleItem(item._id)}>
                            {
                                item.icon({
                                    mainClassName: 'compare-features__table-item-head-icon',
                                    fillClassName: 'compare-features__table-item-head-icon-fill'
                                })
                            }
                            <p className='compare-features__table-item-head-title'>{item.title}</p>
                            <DropArrowIcon
                                mainClassName={'compare-features__table-item-head-arrow'}
                                strokeClassName={'compare-features__table-item-head-arrow-stroke'}
                            />
                        </button>

                        <motion.nav
                            initial={false}
                            animate={selectedItems.includes(item._id) ? "open" : "closed"}
                            custom={height}
                            ref={containerRefItem}
                            // className='compare-features__table-item-inside-items'
                        >
                            <motion.ul variants={variants(item.items.length)}>
                                {item.items.map((item2, i2) => (
                                    <motion.li
                                        variants={variantsLi}
                                        className={`compare-features__table-item-inside-item ${i % 2 !== 0 ? i2 % 2 !== 0 ? 'compare-features__table-item-inside-item_even' : '' : i2 % 2 === 0 ? 'compare-features__table-item-inside-item_odd' : ''}`}
                                        // key={`compare-features__table-item-inside-item${item._id}-${item2._id}`}
                                    >
                                        <div className='compare-features__table-item-inside-item-cell'>
                                            <p className='compare-features__table-item-inside-item-title'>{item2.title}</p>
                                        </div>
                                        <div className='compare-features__table-item-inside-item-answer-cell'>
                                            {item2.standart_value.type === 'text' ?
                                                <p className='compare-features__table-item-inside-item-answer-text'>{item2.standart_value.value}</p>
                                                : null}
                                            {item2.standart_value.type === 'icon' ?
                                                item2.standart_value.value ?
                                                    <TrueTickIcon
                                                        mainClassName={'compare-features__table-item-inside-item-answer-tick'}
                                                        strokeClassName={'compare-features__table-item-inside-item-answer-tick-stroke'}
                                                    />
                                                    :
                                                    // <FalseTickIcon
                                                    //     mainClassName={'compare-features__table-item-inside-item-answer-false-tick'}
                                                    //     strokeClassName={'compare-features__table-item-inside-item-answer-false-tick-stroke'}
                                                    // />
                                                    null
                                                : null}
                                        </div>
                                        <div className='compare-features__table-item-inside-item-answer-cell'>
                                            {item2.plus_value.type === 'text' ?
                                                <p className='compare-features__table-item-inside-item-answer-text'>{item2.plus_value.value}</p>
                                                : null}
                                            {item2.plus_value.type === 'icon' ?
                                                item2.plus_value.value ?
                                                    <TrueTickIcon
                                                        mainClassName={'compare-features__table-item-inside-item-answer-tick'}
                                                        strokeClassName={'compare-features__table-item-inside-item-answer-tick-stroke'}
                                                    />
                                                    :
                                                    // <FalseTickIcon
                                                    //     mainClassName={'compare-features__table-item-inside-item-answer-false-tick'}
                                                    //     strokeClassName={'compare-features__table-item-inside-item-answer-false-tick-stroke'}
                                                    // />
                                                    null
                                                : null}
                                        </div>
                                    </motion.li>
                                ))}
                            </motion.ul>
                        </motion.nav>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CompareFeatures