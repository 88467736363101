import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import App from './components/App/App';
import { BrowserRouter } from 'react-router-dom';

import "swiper/swiper-bundle.css";
import ScrollToTop from './assets/hooks/scrollToTop';


// Wait for fonts to load
const Root = () => {
  const [isFontsLoaded, setFontsLoaded] = useState(false);
  const [, setForceUpdate] = useState(false);
  async function preloadUsedFonts() {
    console.log('🚀 Font Preloading Started');

    // Step 1: Wait until fonts are registered
    await document.fonts.ready;
    console.log('✅ document.fonts.ready');

    const fontFamilies = new Set();
    // Use predefined fonts
    fontFamilies.add('Gorizont');
    fontFamilies.add('ALS Hauss');

    // // Step 2: Extract used fonts from computed styles
    // document.querySelectorAll("*").forEach(el => {
    //   console.log(getComputedStyle(el).fontFamily)
    //   const font = getComputedStyle(el).fontFamily.split(",")[0].replace(/['"]/g, "").trim();
    //   if (font) fontFamilies.add(font);
    // });

    console.log('🔍 Used Fonts:', Array.from(fontFamilies));

    // Step 3: Find font URLs in stylesheets
    const fontUrls = new Map();
    Array.from(document.styleSheets).forEach(sheet => {
      try {
        Array.from(sheet.cssRules || []).forEach(rule => {
          if (rule instanceof CSSFontFaceRule) {
            const fontName = rule.style.fontFamily.replace(/['"]/g, "").trim();
            const fontUrlMatches = [...rule.style.src.matchAll(/url\(["']?([^"')]+)["']?\)/g)].map(match => match[1]);

            if (fontUrlMatches) {
              const fontUrlList = fontUrlMatches.map(match => match.replace(/url\(["']?|["']?\)/g, "")); // Clean URLs

              // Find the best format for the browser
              const preferredFormat = fontUrlList.find(url => url.includes(".woff2")) ||
                fontUrlList.find(url => url.includes(".woff")) ||
                fontUrlList.find(url => url.includes(".ttf")) ||
                fontUrlList.find(url => url.includes(".otf")) ||
                fontUrlList.find(url => url.includes(".eot")); // Only use EOT as a last resort

              if (preferredFormat) {
                if (!fontUrls.get(fontName)) {
                  fontUrls.set(fontName, [preferredFormat])
                } else {
                  fontUrls.set(fontName, fontUrls.get(fontName).concat([preferredFormat]))
                }
              }
            }
          }
        });
      } catch (err) {
        console.warn("⚠️ Skipping stylesheet due to CORS", err);
      }
    });

    console.log('🔗 Font URLs:', fontUrls);

    const fontExtensions = {
      ".woff2": "woff2",
      ".woff": "woff",
      ".ttf": "truetype",
      ".otf": "opentype",
      ".eot": "embedded-opentype"
    };

    const getFontType = (fontUrl) => {
      const ext = Object.keys(fontExtensions).find(ext => fontUrl.includes(ext));
      return ext ? fontExtensions[ext] : "woff"; // Default to WOFF if unknown
    };

    // Step 4: Preload fonts that are actually used
    const fontPromises = Array.from(fontFamilies).flatMap(font => {  // ✅ Use flatMap to flatten arrays
      const localFontUrls = fontUrls.get(font);
      if (!localFontUrls) return [];

      return localFontUrls.map(fontUrl => {
        let fontType = getFontType(fontUrl);
        console.log(`⏳ Loading: ${font} from ${fontUrl}`);

        const fontFace = new FontFace(font, `url(${fontUrl}) format('${fontType}')`);

        return fontFace.load()
          .then(loadedFont => {
            document.fonts.add(loadedFont);
            console.log(`✅ Loaded: ${font}`);
          })
          .catch(err => {
            console.warn(`❌ Failed to load: ${font}, ${err}`);
            return null; // ✅ Ensure this error doesn’t break Promise.all()
          });
      });
    });

    // Step 5: Ensure all fonts are completely loaded before applying
    await Promise.all(fontPromises).then(() => {
      console.log("🎉 All fonts are now fully loaded!");
      document.documentElement.classList.add("fonts-loaded"); // Apply class when ready
    }).catch(() => {
      console.warn("⚠️ Some fonts failed, but continuing...");
      document.documentElement.classList.add("fonts-loaded"); // Apply class when ready
    });
  }

  useEffect(() => {
    preloadUsedFonts()

    const checkFonts = () => document.documentElement.classList.contains("fonts-loaded");

    if (checkFonts()) {
      setFontsLoaded(true);
    } else {
      const interval = setInterval(() => {
        if (checkFonts()) {
          clearInterval(interval);
          setFontsLoaded(true);

          setTimeout(() => {
            setForceUpdate(prev => !prev); // React re-render
            document.body.style.display = "none"; // Force repaint
            requestAnimationFrame(() => (document.body.style.display = ""));
          }, 50);
        }
      }, 100);
    }
  }, []);

  return isFontsLoaded ? <App /> : null;
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <BrowserRouter basename={"/ro"} >
      <ScrollToTop />
      <Root />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
